<template>
  <settings-layout>
    <template #actions>
      <b-button size="sm" variant="link" class="p-0 mr-1" @click="patchSetting">
        <feather-icon icon="UploadCloudIcon" size="16" class="align-middle text-body"/>
      </b-button>
    </template>

    <template #content>
      <b-alert show variant="primary" class="font-small-3">
        <b-icon-info-circle class="mr-50"/>
        These setting effect both Applications and Students.
      </b-alert>

      <template v-if="setting">
        <b-row class="setting-row">
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">Enabled</div>
                <div class="setting-state">
                  <b-form-checkbox v-model="setting.value.enabled"
                                   switch size="sm"
                                   @change="patchSetting"/>
                </div>
              </div>
              <div class="setting-desc">This setting enables/disables the creation and editing of applications.</div>
            </div>
          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">Message</div>
                <div class="setting-state">
                  <b-form-checkbox v-model="setting.value.message.enabled"
                                   switch size="sm"
                                   @change="patchSetting"/>
                </div>
              </div>
              <div class="setting-desc">This setting allows for a custom message to be displayed when applications are disabled. If this setting is disabled, a default message will take it's place.</div>
              <div class="setting-input">
                <b-textarea v-model="setting.value.message.text"></b-textarea>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">Group Override</div>
                <div class="setting-state">
                  <b-checkbox disabled switch size="sm" :checked="true"/>
                </div>
              </div>
              <div class="setting-desc">
                This setting will allow the following groups to ignore the enabled/disabled state of the application.
              </div>
              <div class="setting-input">
                <v-select v-model="setting.value.override.groups" multiple
                          :loading="options.groups.loading"
                          :options="options.groups.items" label="GroupName"
                          :reduce="option => option.GroupName"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          append-to-body :calculate-position="withPopper"
                          placeholder="Groups" class="override-select font-small-3"
                          @open="onGroupsOpen"
                          @input="patchSetting" />
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">User Override</div>
                <div class="setting-state">
                  <b-checkbox disabled switch size="sm" :checked="true"/>
                </div>
              </div>
              <div class="setting-desc">
                This setting will allow the following users to ignore the enabled/disabled state of the application.
              </div>
              <div class="setting-input">
                <v-select v-model="setting.value.override.users" multiple
                        :loading="options.users.loading"
                        :options="options.users.items" label="id"
                        :reduce="option => ({ id: option.id, username: option.username })"
                        :filter="onUserSearch"
                        append-to-body :calculate-position="withPopper"
                        :select-on-tab="true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        placeholder="Users" class="override-select font-small-3"
                        @open="onUsersOpen"
                        @input="patchSetting">
                <template #option="{ name, username }">
                  <h6 class="mb-0">{{ name.first }} {{ name.last }}</h6>
                  <small>@{{ username }}</small>
                </template>
                <template #selected-option="{ id }">
                  {{ getUserName(id) }}
                </template>
              </v-select>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
    </template>

    <template #debug>
      <debug title="setting" collapsed class="mt-2">{{ setting }}</debug>
    </template>
  </settings-layout>
</template>


<script>
import { API, graphqlOperation} from 'aws-amplify';
import { getSetting } from '@/graphql/queries';
import { updateSetting } from '@/graphql/mutations';
import notify from '@/mixins/notify.mixin';
import vSelect from 'vue-select';
import { adminListGroups } from '@/scripts/aws';
import { listUsers } from '@/graphql/queries/settingsApplication';
import SettingsLayout from '@/views/management/settings/SettingsLayout.vue';
import Fuse from 'fuse.js';
import popper from '@/mixins/popper.mixin';
import {BDropdownItem} from 'bootstrap-vue';

export default {
  name: 'SettingsApplication',
  components: {
    BDropdownItem,
    SettingsLayout,
    vSelect
  },
  mixins: [ notify, popper ],
  data() {
    return {
      key: 'application',
      setting: null,
      icon: 'fas fa-gear',
      options: {
        groups: {
          loading: false,
          items: []
        },
        users: {
          loading: false,
          items: []
        }
      },
      popperSettings: {
        placement: 'bottom'
      },
    }
  },
  async mounted() {
    await this.getSetting()
  },
  methods: {
    async getSetting() {
      const response = await API.graphql(graphqlOperation(getSetting, { key: this.key }));
      this.setting = response.data.getSetting
      if(this.setting) {
        this.setting.value = JSON.parse(this.setting.value)
      }
    },
    async patchSetting() {
      try {
        const settingInput = {
          key: this.key,
          value: JSON.stringify(this.setting.value)
        }
        const response = await API.graphql(graphqlOperation(updateSetting, { input: settingInput }));
        this.setting = response.data.updateSetting;
        if(this.setting) {
          this.setting.value = JSON.parse(this.setting.value)
        }
        this.notify({ title: 'Success', text: 'Setting was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        this.notify({ title: 'Error', text: 'Setting failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async onGroupsOpen() {
      if (this.options.groups.items.length === 0) {
        this.options.groups.loading = true
        await this.listGroups()
      }
      this.options.groups.loading = false
      return this.options.groups.items
    },
    async onUsersOpen() {
      if (this.options.users.items.length === 0) {
        this.options.users.loading = true
        await this.listUsers()
      }
      this.options.users.loading = false
      return this.options.users.items
    },

    async listGroups() {
      const cognitoGroupsResponse = await adminListGroups()
      this.options.groups.items = cognitoGroupsResponse.Groups
    },

    onUserSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.first', 'name.last', 'username'],
        shouldSort: true,
        threshold: 0.2
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },

    async listUsers(nextToken, pagedUsers) {
      const users = pagedUsers || []

      /** Get Users from Amplify **/
      const response = await API.graphql(graphqlOperation(listUsers, { limit: 500, nextToken: nextToken }));
      users.push(...response.data.listUsers.items)

      if(response.data.listUsers.nextToken) {
        await this.listUsers(response.data.listUsers.nextToken, users)
      }
      else {
        this.options.users.items = users.sort((a, b) => a.name.first.localeCompare(b.name.first) || a.name.last.localeCompare(b.name.last))
      }
    },

    getUserName(id) {
      const user = this.options.users.items.find(item => item.id === id)
      if(user) {
        return `${user.name.first} ${user.name.last}`
      }
      return this.setting.value.override.users.find(u => u.id === id)?.username || 'Unknown'
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/nyssma/settings.scss';
</style>
