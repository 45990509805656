<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <template v-if="$can('read', 'management-settings-kv')">
            <div class="add-task" >
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  class="shadow-sm"
                  :to="{ name: 'management-settings-kv' }"
              >
                Settings
              </b-button>
            </div>
          </template>
          <template v-else>
            <h6 class="section-label mt-1 mb-1 px-2">
              Settings
            </h6>
          </template>

          <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="sidebar-menu-list scroll-area">
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item v-for="path in filteredPaths" :key="path.title + $route.path" :to="path.route" :active="isDynamicRouteActive(path.route)" @click="$emit('close-left-sidebar')">
                <feather-icon
                    :icon="path.icon"
                    size="18"
                    class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ path.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  props: {
  },
  data() {
    return {
      paths: [
        { title: 'App', icon: 'SettingsIcon', route: { name: 'management-settings-app' } },
        { title: 'Application', icon: 'ClipboardIcon', route: { name: 'management-settings-application' } },
        { title: 'Selection', icon: 'PlusSquareIcon', route: { name: 'management-settings-selection' } },
        { title: 'Billing', icon: 'CreditCardIcon', route: { name: 'management-settings-billing', params: { filter: 'important' } } },
       /* { title: 'Certificates', icon: 'AwardIcon', route: { name: 'management-settings-certificates', params: { filter: 'deleted' } } },*/
        /*{ title: 'Letters', icon: 'FileTextIcon', route: { name: 'management-settings-letters', params: { filter: 'completed' } } },*/
        { title: 'Support', icon: 'HelpCircleIcon', route: { name: 'management-settings-support', params: { filter: 'completed' } } },
      ]
    }
  },
  computed: {
    filteredPaths() {
      return this.paths.filter(path => this.$can('read', path.route.name))
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }
    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

[dir] .todo-application .content-area-wrapper .sidebar .todo-sidebar .todo-app-menu .list-group .list-group-item.active {
  border-color: #F1C243!important;
}
</style>
