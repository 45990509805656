<template>
  <div style="height: inherit">
    <div class="body-content-overlay" :class="{'show': mqShallShowLeftSidebar}" @click="mqShallShowLeftSidebar = false"/>

    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center py-1">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
              icon="MenuIcon"
              size="21"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
<!--          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
              />
            </b-input-group-prepend>
            <slot name="search">
              <b-form-input placeholder="Search" @input="search"/>
            </slot>
          </b-input-group>-->
        </div>

        <!-- Actions -->
        <div class="mr-1">
          <slot name="actions">

          </slot>
        </div>

        <!-- Options -->
        <div class="dropdown">
          <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>
            <slot name="options">

            </slot>
            <b-dropdown-divider v-if="$slots.options"/>
            <b-dropdown-item @click="state.debug = !state.debug">
              Debug: {{ state.debug ? 'On' : 'Off' }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="todo-task-list-wrapper list-group scroll-area">
        <div class="todo-task-list-wrapper py-1 px-2">
          <slot name="content">

          </slot>
        </div>
        <can v-if="state.debug" do="debug">
          <div class="p-1">
            <slot name="debug">

            </slot>
          </div>
        </can>
      </vue-perfect-scrollbar>

    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <settings-sidebar
          :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
          :class="{'show': mqShallShowLeftSidebar}"
          @close-left-sidebar="mqShallShowLeftSidebar = false"/>
    </portal>
  </div>
</template>


<script>
import store from '@/store'
import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { formatDate, avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import SettingsSidebar from './SettingsSidebar.vue'

export default {
  components: {
    draggable,
    VuePerfectScrollbar,
    SettingsSidebar,
  },
  setup() {
    const { route, router } = useRouter()
    const perfectScrollbarSettings = { maxScrollbarLength: 150 }
    const isTaskHandlerSidebarActive = ref(false)

    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      perfectScrollbarSettings,
      updateRouteQuery,
      isTaskHandlerSidebarActive,
      formatDate,
      avatarText,
      mqShallShowLeftSidebar,
    }
  },
  data: () => ({
    state: {
      debug: false,
    }
  }),
  methods: {
    search(val) {
      this.$emit('search', val)
    },
  },
}
</script>

<style lang="scss">
  @import "~@core/scss/base/pages/app-todo.scss";
</style>
